import Vue from 'vue'

import { global } from '@/configurations/mixins'
import { background, backgroundCentered, backgroundCover, clickOutside, icon } from '@/configurations/directives'
import FontMixin from '~/configurations/mixins/FontMixin'

if(!Vue.__global_mixin_set__) {
  if(process.client) {
    Vue.directive('background', background)
    Vue.directive('background-centered', backgroundCentered)
    Vue.directive('background-cover', backgroundCover)
    Vue.directive('icon', icon)
    Vue.directive('click-outside', clickOutside)
  }
  Vue.mixin(global)
  Vue.mixin(FontMixin)
  Vue.__global_mixin_set__ = true
}

// eslint-disable-next-line no-extend-native
String.prototype.replaceAll = function (find, replace) {
  return this.replace(new RegExp(find, 'g'), replace)
}

export default ({ app, $config, store }, inject) => {

}
