import Vue from 'vue'

import LoginForm from '@/components/form/LoginForm'

function interopDefault(promise) {
  return promise.then(m => m.default || m);
}

const RegistrationForm = () => interopDefault(import('@/components/form/RegistrationForm'))
const RegistrationAdditionalForm = () => interopDefault(import('@/components/form/RegistrationAdditionalForm'))
const PasswordResetRequestForm = () => interopDefault(import('@/components/form/PasswordResetRequestForm'))
const PasswordResetForm = () => interopDefault(import('@/components/form/PasswordResetForm'))
const GameInterestForm = () => interopDefault(import('@/components/layout/user/subviews/UserGameInterests'))
const UserCMSTitleSubscription = () => interopDefault(import('@/components/layout/user/subviews/UserCMSTitleSubscription'))
const EmailChangeForm = () => interopDefault(import('@/components/form/EmailChangeForm'))
const DCIChangeForm = () => interopDefault(import('@/components/form/DCIChangeForm'))
const ResendActivationForm = () => interopDefault(import('@/components/form/ResendActivationForm'))
const UsernameChangeForm = () => interopDefault(import('@/components/form/UsernameChangeForm'))
const PasswordChangeForm = () => interopDefault(import('@/components/form/PasswordChangeForm'))
const PhoneChangeForm = () => interopDefault(import('@/components/form/PhoneChangeForm'))
const ShippingAddressForm = () => interopDefault(import('@/components/form/ShippingAddressForm'))
const BillingAddressForm = () => interopDefault(import('@/components/form/BillingAddressForm'))
const ContactUsForm = () => interopDefault(import('@/components/form/ContactUsForm'))
const CareerOfferForm = () => interopDefault(import('@/components/form/CareerOfferForm'))
const WantlistRequest = () => interopDefault(import('@/components/general/wantlists/WantlistRequest'))
const WantlistBuyChoice = () => interopDefault(import('@/components/general/wantlists/WantlistBuyChoice'))
const UPSLocationPicker = () => interopDefault(import('@/components/layout/checkout/UPSLocationPicker'))
const AlertDialog = () => interopDefault(import('@/components/layout/modal/AlertDialog'))
const SinglesFilter = () => interopDefault(import('@/components/layout/sideNavigation/subviews/filters/SinglesFilters'))
const BuylistFilter = () => interopDefault(import('@/components/layout/sideNavigation/subviews/filters/BuylistFilters'))
const SearchFilter = () => interopDefault(import('@/components/layout/sideNavigation/subviews/filters/SearchFilters'))
const CMSArticleFilter = () => interopDefault(import('@/components/layout/sideNavigation/subviews/filters/CMSArticleFilters'))
const NonsingleFiltersTabs = () => interopDefault(import('~/components/layout/catalog/NonsingleFiltersTabs'))
const CartToShoppingListForm = () => interopDefault(import('~/components/form/CartToShoppingListForm'))
const AddToShoppingListForm = () => interopDefault(import('~/components/form/AddToShoppingListForm'))
const AddShoppingListToCartForm = () => interopDefault(import('~/components/form/AddShoppingListToCartForm'))

const MessageAlert = () => interopDefault(import('@/components/general/popups/MessageAlert'))
const ChoiceDialog = () => interopDefault(import('@/components/layout/modal/ChoiceDialog'))

const SellTransportPicker = () => interopDefault(import('@/components/layout/checkout/helpers/SellTransportPicker'))
const SellPaymentPicker = () => interopDefault(import('@/components/layout/checkout/helpers/SellPaymentPicker'))
const BuyTransportPicker = () => interopDefault(import('@/components/layout/checkoutBuyup/helpers/BuyTransportPicker'))
const BuyPaymentPicker = () => interopDefault(import('@/components/layout/checkoutBuyup/helpers/BuyPaymentPicker'))
const BankTransferInfo = () => interopDefault(import('@/components/general/popups/BankTransferInfo'))
const BalikobotLocationPicker = () => interopDefault(import('@/components/layout/checkout/BalikobotLocationPicker'))

const AddressSwitch = () => interopDefault(import('@/components/layout/checkout/helpers/AddressSwitch'))
const BulkParserSettings = () => interopDefault(import('@/components/layout/sideNavigation/subviews/bulkOperations/BulkParserSettings.vue'))


const GCTournamentEnrollForm = () => interopDefault(import('@/components/gaming-club/forms/GCTournamentEnrollForm'))
const GCTournamentDisenrollForm = () => interopDefault(import('@/components/gaming-club/forms/GCTournamentDisenrollForm'))
const GCTournamentListPicker = () => interopDefault(import('@/components/gaming-club/forms/GCTournamentListPicker'))
const GCProfileForm = () => interopDefault(import('@/components/gaming-club/forms/GCProfileForm.vue'))
const GCTournamentDecklistForm = () => interopDefault(import('@/components/gaming-club/forms/GCTournamentDecklistForm.vue'))

const Modal = {

  eventBus: new Vue(),

  /**
   * @typedef {Object} ModalConfiguration
   * @property {String} title
   * @property {String|null|undefined} size
   * @property {Function} component
   * @property {Object} data
   * @property {Function} onTaskCompleted
   * @property {Function} onConfirm
   */

  /**
   *
   * @param {ModalConfiguration} params
   */
  show (params) {
    // if we use this.$modal.show(params) inside our original Vue instance
    // we will emit 'show' event with parameters 'params'
    Modal.eventBus.$emit('show', params)
  },
  hide () {
    Modal.eventBus.$emit('hide')
  },
  showAlertDialog ({ message, onComplete, listeners }) {
    this.show({
      component: AlertDialog,
      title: 'modal_title_warning',
      data: {
        message,
        listeners
      },
      onTaskCompleted () {
        onComplete()
      }
    })
  },
  showChoiceDialog ({ message, choiceLeft, choiceRight, listeners }) {
    this.show({
      component: ChoiceDialog,
      title: 'modal_title_warning',
      data: {
        message,
        choiceLeft,
        choiceRight,
        listeners
      },
    })
  },
  showMessageAlert ({ title = 'modal_title_warning', message = '' }) {
    const oThis = this

    this.show({
      component: MessageAlert,
      size: 'middle',
      title,
      data: {
        message
      },
      onTaskCompleted () {
        oThis.hide()
      }
    })
  },
  showBankTransferInfo ({ order }) {
    const oThis = this

    this.show({
      component: BankTransferInfo,
      size: 'middle',
      title: 'modal_title_bank_transfer_info',
      data: { order },
      onTaskCompleted () {
        oThis.hide()
      }
    })
  },
  /**
   *
   * @param {function} onComplete
   * @param {string|null} message
   */
  showLogin (onComplete = function () {}, message = null) {
    const oThis = this

    this.show({
      component: LoginForm,
      title: 'modal_title_login',
      data: { message },
      onTaskCompleted () {
        oThis.hide()
        onComplete()
      }
    })
  },
  showRegistration () {
    const oThis = this

    this.show({
      component: RegistrationForm,
      size: 'large',
      title: 'modal_title_registration',
      onTaskCompleted () {
        oThis.showRegistrationResult()
      }
    })
  },
  showRegistrationAdditional () {
    const oThis = this

    this.show({
      component: RegistrationAdditionalForm,
      size: 'middle',
      title: '',
      onTaskCompleted () {
        oThis.hide()
      }
    })
  },
  showRegistrationResult () {
    const oThis = this

    this.show({
      component: MessageAlert,
      size: 'middle',
      title: 'modal_title_registration',
      data: {
        message: 'registration_result_successful'
      },
      onTaskCompleted () {
        oThis.hide()
      }
    })
  },
  showPasswordResetRequest (onComplete = function () {}) {
    this.show({
      component: PasswordResetRequestForm,
      size: 'small',
      title: 'modal_title_password_change',
      onTaskCompleted () {
        onComplete()
      }
    })
  },
  showPasswordReset ({ uid, token, onComplete = function () {} }) {
    this.show({
      component: PasswordResetForm,
      size: 'small',
      title: 'modal_title_password_change',
      data: {
        uid,
        token
      },
      onTaskCompleted () {
        onComplete()
      }
    })
  },
  showEmailChange () {
    const oThis = this

    this.show({
      component: EmailChangeForm,
      size: 'small',
      title: 'modal_title_email_change',
      onTaskCompleted () {
        oThis.hide()
      }
    })
  },
  showPhoneChange () {
    const oThis = this

    this.show({
      component: PhoneChangeForm,
      size: 'small',
      title: 'modal_title_phone_change',
      data: {},
      onTaskCompleted () {
        oThis.hide()
      }
    })
  },
  showPasswordChange () {
    const oThis = this

    this.show({
      component: PasswordChangeForm,
      size: 'small',
      title: 'modal_title_password_change',
      onTaskCompleted () {
        oThis.hide()
      }
    })
  },
  showResendActivation () {
    const oThis = this

    this.show({
      component: ResendActivationForm,
      size: 'small',
      title: 'modal_title_resend_activation',
      onTaskCompleted () {
        oThis.hide()
      }
    })
  },
  showCmsSubscriptionTitlesForm () {
    const oThis = this

    this.show({
      component: UserCMSTitleSubscription,
      size: 'middle',
      title: 'modal_title_cms_titles_subscription',
      onTaskCompleted () {
        oThis.hide()
      }
    })
  },
  showGameInterestsForm () {
    const oThis = this

    this.show({
      component: GameInterestForm,
      size: 'middle',
      title: 'modal_title_game_interests',
      onTaskCompleted () {
        oThis.hide()
      }
    })
  },
  showDCIChange () {
    const oThis = this

    this.show({
      component: DCIChangeForm,
      size: 'small',
      title: 'modal_title_dci_change',
      onTaskCompleted () {
        oThis.hide()
      }
    })
  },
  showUsernameChange () {
    const oThis = this

    this.show({
      component: UsernameChangeForm,
      size: 'small',
      title: 'modal_title_username_change',
      onTaskCompleted () {
        oThis.hide()
      }
    })
  },
  showCartToShoppingList (hideOnConfirm = true) {
    const oThis = this

    this.show({
      component: CartToShoppingListForm,
      size: 'middle',
      title: 'modal_title_sl_cart_to_list',
      data: {  },
      onTaskCompleted (address) {
        if(hideOnConfirm) {
          oThis.hide()
        }
      }
    })
  },
  showAddToShoppingListForm (data, hideOnConfirm = true) {
    const oThis = this

    this.show({
      component: AddToShoppingListForm,
      size: 'middle',
      title: 'modal_title_sl_add_item_to_list',
      data,
      onTaskCompleted (address) {
        if(hideOnConfirm) {
          oThis.hide()
        }
      }
    })
  },
  showAddShoppingListToCart ({list, onCompleted = function (){}}) {

    this.show({
      component: AddShoppingListToCartForm,
      size: 'xl',
      title: 'modal_title_sl_list_to_cart',
      data: {
        list
      },
      onTaskCompleted (address) {
        onCompleted()
      }
    })
  },
  showBillingAddressForm (address = null, onConfirm = () => {}, hideOnConfirm = true) {
    const oThis = this

    this.show({
      component: BillingAddressForm,
      size: 'small',
      title: 'modal_title_billing_address',
      data: { address },
      onTaskCompleted (address) {
        if(hideOnConfirm) {
          oThis.hide()
        }

        onConfirm(address)
      }
    })
  },
  showShippingAddressForm (address = null, onConfirm = () => {}, hideOnConfirm = true) {
    const oThis = this

    this.show({
      component: ShippingAddressForm,
      size: 'small',
      title: 'modal_title_new_address',
      data: {
        address,
        value: null
      },
      onTaskCompleted (address) {
        if(hideOnConfirm) {
          oThis.hide()
        }

        onConfirm(address)
      }
    })
  },
  showAddressSwitch (params) {
    const oThis = this

    this.show({
      component: AddressSwitch,
      size: 'large',
      title: 'modal_title_change_address',
      data: {},
      onTaskCompleted () {
        oThis.hide()
      },
      ...params
    })
  },

  /**
   * @param {Object} data
   * @param {String} data.placeholder
   */
  showContactUsForm ({ placeholder = null, blob = null, modelData = {}, listeners = {}, screenshotable = false }) {
    const oThis = this

    this.show({
      component: ContactUsForm,
      size: 'middle',
      title: 'modal_title_contact_us',
      data: {
        screenshotable,
        placeholder,
        listeners,
        modelData,
        blob
      },
      onTaskCompleted () {
        oThis.showMessageAlert({
          title: 'modal_title_contact_us_success',
          message: 'contact_us_success_message'
        })
      }
    })
  },
  showCareerOfferForm ({ title, ...params }) {
    const oThis = this

    this.show({
      component: CareerOfferForm,
      size: 'large',
      title,
      data: {
        ...params
      },
      onTaskCompleted () {
        oThis.showMessageAlert({
          title: 'modal_title_contact_us_success',
          message: 'contact_us_success_message'
        })
      }
    })
  },
  showWantlistRequest ({ article, value = null }) {
    const oThis = this

    this.show({
      component: WantlistRequest,
      size: 'middle',
      title: 'modal_title_wantlist_request',
      data: { article, value },
      onTaskCompleted () {
        oThis.hide()
      }
    })
  },
  showWantlistBuyChoice ({ title = 'modal_title_wantlist_buy_choice', wantlistItem, lazy = true, onConfirm }) {
    const oThis = this

    this.show({
      component: WantlistBuyChoice,
      size: 'xl',
      title,
      data: {
        wantlist: wantlistItem,
        lazy
      },
      confirmTitle: 'action_add_to_cart',
      onConfirm,
      onTaskCompleted () {
        oThis.hide()
      }
    })
  },
  showUPSLocationPicker ({ onConfirm, ...data }) {
    const oThis = this

    this.show({
      component: UPSLocationPicker,
      size: 'xl',
      title: 'modal_title_ups_location',
      data: {
        ...data,
        lazy: true
      },
      onConfirm,
      onTaskCompleted () {
        oThis.hide()
      }
    })
  },

  showSellTransportPicker ({ value, items, lazy = true, groupped = false, listeners, onConfirm }) {
    const oThis = this

    this.show({
      component: SellTransportPicker,
      size: 'xl',
      title: 'checkout_transport_title',
      data: {
        value,
        items,
        groupped,
        lazy,
        listeners
      },
      onConfirm,
      onTaskCompleted () {
        oThis.hide()
      }
    })
  },
  showSellPaymentPicker ({ value, items, lazy = true, showValidation, onConfirm }) {
    const oThis = this

    this.show({
      component: SellPaymentPicker,
      size: 'xl',
      title: 'checkout_payment_title',
      data: {
        value,
        items,
        lazy,
        showValidation
      },
      onConfirm,
      onTaskCompleted () {
        oThis.hide()
      }
    })
  },

  showBuyTransportPicker ({ value, items, lazy = true, listeners, onConfirm }) {
    const oThis = this

    this.show({
      component: BuyTransportPicker,
      size: 'xl',
      title: 'checkout_transport_title',
      data: {
        value,
        items,
        lazy,
        listeners
      },
      onConfirm,
      onTaskCompleted () {
        oThis.hide()
      }
    })
  },
  showBuyPaymentPicker ({ value, items, showValidation, lazy = true, onConfirm }) {
    const oThis = this

    this.show({
      component: BuyPaymentPicker,
      size: 'xl',
      title: 'checkout_payment_title',
      data: {
        value,
        items,
        showValidation,
        lazy
      },
      onConfirm,
      onTaskCompleted () {
        oThis.hide()
      }
    })
  },
  showBalikobotLocationPicker ({ value = null, country, zip, method, onConfirm }) {
    const oThis = this

    this.show({
      component: BalikobotLocationPicker,
      size: 'middle',
      title: 'checkout_balikobot_location_title',
      data: {
        value,
        lazy: true,
        country,
        zip,
        method
      },
      onConfirm,
      onTaskCompleted () {
        oThis.hide()
      }
    })
  },

  showNonsinglesFilter ({ lazy = true, value, pageFilters = [], onConfirm }) {
    const oThis = this

    this.show({
      component: NonsingleFiltersTabs,
      size: 'xl',
      title: 'modal_title_nonsingles_filter',
      data: {
        value,
        lazy,
        pageFilters
      },
      onConfirm,
      onTaskCompleted () {
        oThis.hide()
      }
    })
  },

  showSinglesFilter ({ lazy = true, value, onConfirm, page }) {
    const oThis = this

    this.show({
      component: SinglesFilter,
      size: 'xl',
      title: 'modal_title_cards_filter_singles_sell',
      data: {
        value,
        lazy,
        page
      },
      onConfirm,
      onTaskCompleted () {
        oThis.hide()
      }
    })
  },

  showBuylistFilter ({ lazy = true, value, page, onConfirm }) {
    const oThis = this

    this.show({
      component: BuylistFilter,
      size: 'xl',
      title: 'modal_title_cards_filter_singles_buy',
      data: {
        lazy,
        value,
        page
      },
      onConfirm,
      onTaskCompleted () {
        oThis.hide()
      }
    })
  },

  showSearchFilter ({ lazy = true, value, onConfirm }) {
    const oThis = this

    this.show({
      component: SearchFilter,
      size: 'xl',
      title: 'modal_title_cards_filter_singles_sell',
      data: {
        lazy,
        value
      },
      onConfirm,
      onTaskCompleted () {
        oThis.hide()
      }
    })
  },

  showBulkParserSettings ({ lazy = true, value, onConfirm }) {
    const oThis = this

    this.show({
      component: BulkParserSettings,
      size: 'xl',
      title: 'modal_title_nonsingles_filter',
      data: {
        value,
        lazy
      },
      onConfirm,
      onTaskCompleted () {
        oThis.hide()
      }
    })
  },

  showBlogFilter ({ lazy = true, value, onConfirm }) {
    const oThis = this

    this.show({
      component: CMSArticleFilter,
      size: 'xl',
      title: 'modal_title_blog_filter',
      data: {
        value,
        lazy
      },
      onConfirm,
      onTaskCompleted () {
        oThis.hide()
      }
    })
  },

  showGCTournamentEnrollForm ({ listeners = {}, ...data }) {
    const oThis = this

    this.show({
      component: GCTournamentEnrollForm,
      size: 'large',
      title: 'modal_title_gc_tournament_enroll_form',
      data: {
        ...data,
        listeners
      },
      onTaskCompleted () {
        oThis.hide()
      }
    })
  },

  showGCTournamentDisenrollForm ({ listeners = {}, ...data }) {
    const oThis = this

    this.show({
      component: GCTournamentDisenrollForm,
      size: 'large',
      title: 'modal_title_gc_tournament_disenroll_form',
      data: {
        ...data,
        listeners
      },
      onTaskCompleted () {
        oThis.hide()
      }
    })
  },

  showGCTournamentListPicker ({ listeners = {}, title, ...data }) {
    const oThis = this

    this.show({
      component: GCTournamentListPicker,
      size: 'large',
      title,
      data: {
        ...data,
        listeners
      },
      onTaskCompleted () {
        oThis.hide()
      }
    })
  },

  showGCTournamentDecklistForm ({ listeners = {}, title, ...data }) {

    this.show({
      component: GCTournamentDecklistForm,
      size: 'middle',
      title: 'modal_title_gc_tournament_decklist_form',
      data: {
        ...data,
        listeners
      },
      onTaskCompleted () {
        listeners.success()
      }
    })
  },

  showGCProfileForm ({ listeners = {}, title, ...data }) {

    this.show({
      component: GCProfileForm,
      size: 'middle',
      title,
      data: {
        ...data,
        listeners
      },
      onTaskCompleted () {
        listeners.success()
      }
    })
  }
}

export default ({ app }, inject) => {
  inject('modal', Modal)
}
