import Vue from 'vue'
import('vue-toastification').then(result => {
  Vue.use(result.default, {
    closeButton: false,
    closeOnClick: true,
    draggable: true,
    draggablePercent: 0.6,
    hideProgressBar: true,
    icon: true,
    maxToasts: 20,
    newestOnTop: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    position: "top-center",
    rtl: false,
    showCloseButtonOnHover: false,
    timeout: 4000,
    toastClassName: 'font-hind',
    transition: "Vue-Toastification__slideBlurred",
  })
})
