import Vue from 'vue'

Promise.allValues({
  getAwesomeSwiper: import('vue-awesome-swiper/dist/exporter'),
  swiperClass: import('swiper/esm/components/core/core-class'),
  virtual: import('swiper/esm/components/virtual/virtual'),
  pagination: import('swiper/esm/components/pagination/pagination'),
  navigation: import('swiper/esm/components/navigation/navigation'),
  mousewheel: import('swiper/esm/components/mousewheel/mousewheel'),
  autoplay: import('swiper/esm/components/autoplay/autoplay'),
  lazy: import('swiper/esm/components/lazy/lazy'),
  controller: import('swiper/esm/components/controller/controller'),
}).then(result => {
  result.swiperClass.default.use([
    result.controller.default,
    result.virtual.default,
    result.pagination.default,
    result.mousewheel.default,
    result.navigation.default,
    result.autoplay.default,
    result.lazy.default,
  ])
  Vue.use(result.getAwesomeSwiper.default(result.swiperClass.default))
  const { Swiper, SwiperSlide } = result.getAwesomeSwiper.default(result.swiperClass.default)
  Vue.component('Swiper', Swiper)
  Vue.component('SwiperSlide', SwiperSlide)
})

// SwiperClass.use([Controller, Virtual, Pagination, Mousewheel, Navigation, Autoplay, Lazy])
// Vue.use(getAwesomeSwiper(SwiperClass))
// const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)
//
// Vue.component('Swiper', Swiper)
// Vue.component('SwiperSlide', SwiperSlide)
