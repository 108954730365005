import values from 'lodash/values'
import keys from 'lodash/keys'
import zipObject from 'lodash/zipObject'

Promise.allValues = async (object, additions = []) => {
  return zipObject(
    [...keys(object)],
    (await Promise.all([...values(object), ...additions])).slice(0, keys(object).length),
  )
}
