import Vue from 'vue'
import Router, {NavigationFailureType, isNavigationFailure} from 'vue-router'


import NavigationPage from '~/database/models/NavigationPage'
import {
  multilanguageRoute
} from '~/services/RouterService'

function interopDefault(promise) {
  return promise.then(m => m.default || m);
}

const CheckoutWrapper = () => interopDefault(import('~/components/layout/checkout/CheckoutWrapper'))
const CheckoutBuyupWrapper = () => interopDefault(import('~/components/layout/checkoutBuyup/CheckoutBuyupWrapper'))
const ContentSwitchWrapper = () => interopDefault(import('~/components/layout/ContentSwitchWrapper'))
const UserWrapper = () => interopDefault(import('~/components/layout/user/UserWrapper'))
const CheckoutBuyupCompleted = () => interopDefault(import('~/pages/checkout/CheckoutBuyupCompleted'))
const CheckoutBuyupStep1 = () => interopDefault(import('~/pages/checkout/CheckoutBuyupStep1'))
const CheckoutBuyupStep2 = () => interopDefault(import('~/pages/checkout/CheckoutBuyupStep2'))
const CheckoutBuyupStep3 = () => interopDefault(import('~/pages/checkout/CheckoutBuyupStep3'))
const CheckoutCompleted = () => interopDefault(import('~/pages/checkout/CheckoutCompleted'))
const CheckoutStep1 = () => interopDefault(import('~/pages/checkout/CheckoutStep1'))
const CheckoutStep2 = () => interopDefault(import('~/pages/checkout/CheckoutStep2'))
const CheckoutStep3 = () => interopDefault(import('~/pages/checkout/CheckoutStep3'))
const CareerPage = () => interopDefault(import('~/pages/contact/CareerPage'))
const SearchResultPage = () => interopDefault(import('~/pages/search/SearchResultPage'))
const ClaimsPage = () => interopDefault(import('~/pages/about/ClaimsPage'))
const OrderRatingTermsPage = () => interopDefault(import('~/pages/about/OrderRatingTermsPage'))
const GeneralContestTermsPage = () => interopDefault(import('~/pages/about/GeneralContestTermsPage'))

const GradingTutorialPage = () => interopDefault(import('~/pages/about/GradingTutorialPage'))
const PrivacyPage = () => interopDefault(import('~/pages/about/PrivacyPage'))
const RegistrationBenefitsPage = () => interopDefault(import('~/pages/about/RegistrationBenefitsPage'))
const ShoppingBuylistPage = () => interopDefault(import('~/pages/about/ShoppingBuylistPage'))
const ShoppingLoyaltyPage = () => interopDefault(import('~/pages/about/ShoppingLoyaltyPage'))
const ShoppingPaymentPage = () => interopDefault(import('~/pages/about/ShoppingPaymentPage'))
const ShoppingShippingPage = () => interopDefault(import('~/pages/about/ShoppingShippingPage'))
const ShoppingWantlistPage = () => interopDefault(import('~/pages/about/ShoppingWantlistPage'))
const ShoppingRewardPage = () => interopDefault(import('~/pages/about/ShoppingRewardPage'))
const TermsPage = () => interopDefault(import('~/pages/about/TermsPage'))
const AffiliateProgramPage = () => interopDefault(import('~/pages/about/AffiliateProgramPage'))
const GamingClubAboutPage = () => interopDefault(import('~/pages/about/GamingClubAboutPage'))
const BlogDetailPage = () => interopDefault(import('~/pages/blog/BlogDetailPage'))
const BlogPage = () => interopDefault(import('~/pages/blog/BlogPage'))
const DetailPage = () => interopDefault(import('~/pages/catalog/DetailPage'))
const MTGBuylistBulkPage = () => interopDefault(import('~/pages/catalog/MTGBuylistBulkPage'))
const MTGBuylistProductsPage = () => interopDefault(import('~/pages/catalog/MTGBuylistProductsPage'))
const MTGBuylistSinglesPage = () => interopDefault(import('~/pages/catalog/MTGBuylistSinglesPage'))
const MTGSinglesPage = () => interopDefault(import('~/pages/catalog/MTGSinglesPage'))
const MTGPurchaseBulkPage = () => interopDefault(import('~/pages/catalog/MTGPurchaseBulkPage'))
const SinglesSellPage = () => interopDefault(import('~/pages/catalog/SinglesSellPage'))

const OrderRatingPage = () => interopDefault(import('~/pages/checkout/OrderRatingPage'))
const OrderOverviewPage = () => interopDefault(import('~/pages/checkout/OrderOverviewPage'))
const SellOrderOverviewPage = () => interopDefault(import('~/pages/checkout/SellOrderOverviewPage'))
const ContactPage = () => interopDefault(import('~/pages/contact/ContactPage'))
const AccountActivationPage = () => interopDefault(import('~/pages/user/AccountActivationPage'))
const PasswordResetPage = () => interopDefault(import('~/pages/user/PasswordResetPage'))
const RouterWrapView = () => interopDefault(import('~/components/layout/pages/RouterWrapView'))
const DynamicPage = () => interopDefault(import('~/pages/catalog/DynamicPage'))
const UserAddressesPage = () => interopDefault(import('~/pages/user/UserAddressesPage'))
const UserBuyupsPage = () => interopDefault(import('~/pages/user/UserBuyupsPage'))
const UserCreditPage = () => interopDefault(import('~/pages/user/UserCreditPage'))
const UserOrdersPage = () => interopDefault(import('~/pages/user/UserOrdersPage'))
const UserShoppingListsPage = () => interopDefault(import('~/pages/user/UserShoppingListsPage'))
const UserSettingsPage = () => interopDefault(import('~/pages/user/UserSettingsPage'))
const UserTournamentsPage = () => interopDefault(import('~/pages/user/UserTournamentsPage'))
const UserVoucherPage = () => interopDefault(import('~/pages/user/UserVoucherPage'))
const UserWantlistPage = () => interopDefault(import('~/pages/user/UserWantlistPage'))

const ShoppingListOverviewPage = () => interopDefault(import('~/pages/shopping-list/ShoppingListSharePage'))

const GCLeaguePage = () => interopDefault(import('~/pages/gaming-club/GCLeaguePage'))
const GCLeagueHistoryPage = () => interopDefault(import('~/pages/gaming-club/GCLeagueHistoryPage'))
const GCLeagueStatisticsPage = () => interopDefault(import('~/pages/gaming-club/GCLeagueStatisticsPage'))
const GCUserTournamentsPage = () => interopDefault(import('~/pages/gaming-club/GCUserTournamentsPage'))
const GCTournamentsPage = () => interopDefault(import('~/pages/gaming-club/GCTournamentsPage'))
const GCTournamentsSchedulePage = () => interopDefault(import('~/pages/gaming-club/GCTournamentsSchedulePage'))
const GCPlayerSettingsPage = () => interopDefault(import('~/pages/gaming-club/GCPlayerSettingsPage'))
const GCPlayerStatsOverviewPage = () => interopDefault(import('~/pages/gaming-club/GCPlayerStatsOverviewPage'))
const GCLeagueRulesPage = () => interopDefault(import('~/pages/gaming-club/GCLeagueRulesPage.vue'))

const Homepage = () => interopDefault(import('~/pages/Homepage'))
const TechnicalAlert = () => interopDefault(import('~/pages/TechnicalAlert'))

Vue.use(Router)


if(!Router.__global_push_set__) {
  const originalPush = Router.prototype.push
  Router.prototype.push = function push (location) {
    return originalPush.call(this, location).catch((error) => {
      if (
        !isNavigationFailure(error, NavigationFailureType.aborted) &&
        !isNavigationFailure(error, NavigationFailureType.duplicated) &&
        !isNavigationFailure(error, NavigationFailureType.redirected) &&
        !isNavigationFailure(error, NavigationFailureType.cancelled)) {
        throw new Error(error)
      }
    })
  }
  Router.__global_push_set__ = true
}

const prependedRoutes = []

if(process.env.APP_TECHNICAL_ALERT && process.env.APP_TECHNICAL_ALERT === 'true') {
  prependedRoutes.push({
    name: 'technicalAlert',
    path: '/*',
    component: TechnicalAlert
  })
}

export function createRouter() {
  return new Router({
    mode: 'history',
    scrollBehavior (to, from, savedPosition) {
      return { x: 0, y: 0 }
    },
    routes: [
      ...prependedRoutes,
      {
        name: 'home',
        path: '/',
        meta: {
          layout: 'main',
          homepage: true,
          sidebar: 'homepage',
          breadcrumb: {
            label: 'home',
            name: 'home'
          }
        },
        component: Homepage
      },
      ...multilanguageRoute({
        paths: {
          cs: '/nakupni-seznam/:id',
          en: '/shopping-list/:id'
        },
        name: 'shoppingListSummaryPage',
        component: ShoppingListOverviewPage,
        meta: {
          layout: 'main',
          breadcrumb: {
            label: 'page_shoppingList_summary',
            name: 'shoppingListSummaryPage'
          }
        },
        props: (route) => {
          return {...route.query, ...route.params}
        }
      }),
      ...multilanguageRoute({
        name: 'productDetail',
        paths: {
          cs: '/produkt/:titleSKU',
          en: '/product/:titleSKU',
        },
        component: DetailPage,
        meta: {
          sidebar: 'detail',
          layout: 'main'
        },
        props: (route) => {
          return route.params
        }
      }),
      ...multilanguageRoute(
      {
        name: 'accountActivation',
        paths: {
          cs: '/aktivace/:uid/:token',
          en: '/activate/:uid/:token'
        },
        component: AccountActivationPage,
        meta: {
          sidebar: 'homepage',
          layout: 'main',
          breadcrumb: {
            name: 'accountActivation',
            label: 'page_account_activation'
          }
        },
        props: (route) => {
          return route.params
        }
      }),
      ...multilanguageRoute({
        name: 'passwordResetConfirmation',
        paths: {
          cs: '/heslo/reset/potvrzeni/:uid/:token',
          en: '/password/reset/confirm/:uid/:token'
        },
        component: PasswordResetPage,
        meta: {
          sidebar: 'homepage',
          layout: 'main',
          breadcrumb: {
            name: 'passwordResetConfirmation',
            label: 'page_password_reset_confirmation'
          }
        },
        props: (route) => {
          return route.params
        }
      }),
      ...multilanguageRoute({
        name: 'contactPage',
        paths: {
          cs: '/kontakt',
          en: '/contact'
        },
        component: ContactPage,
        meta: {
          layout: 'main',
          breadcrumb: {
            label: 'page_contact',
            name: 'contactPage'
          }
        },
        props: (route) => {
          return route.params
        }
      }),
      ...multilanguageRoute({
        name: 'affiliateProgramPage',
        paths: {
          cs: '/affiliate-program',
          en: '/affiliate-program'
        },
        component: AffiliateProgramPage,
        meta: {
          layout: 'main',
          breadcrumb: {
            label: 'page_affiliate_program',
            name: 'affiliateProgramPage'
          }
        },
        props: (route) => {
          return route.params
        }
      }),
      ...multilanguageRoute({
        paths: {
          cs: '/hodnoceni-objednavky',
          en: '/order-rating'
        },
        name: 'orderRatingPage',
        component: OrderRatingPage,
        meta: {
          layout: 'main',
          breadcrumb: {
            label: 'page_order_rating_title',
            name: 'orderRatingPage'
          }
        },
        props: (route) => {
          return route.query
        }
      }),
      ...multilanguageRoute({
        paths: {
          cs: '/shrnuti-objednavky',
          en: '/order-summary'
        },
        name: 'orderSummaryPage',
        component: OrderOverviewPage,
        meta: {
          layout: 'main',
          breadcrumb: {
            label: 'page_order_summary',
            name: 'orderSummaryPage'
          }
        },
        props: (route) => {
          return route.query
        }
      }),
      ...multilanguageRoute({
        paths: {
          cs: '/shrnuti-vykupu',
          en: '/sell-order-summary'
        },
        name: 'sellOrderSummaryPage',
        component: SellOrderOverviewPage,
        meta: {
          layout: 'main',
          breadcrumb: {
            label: 'page_sell_order_summary',
            name: 'sellOrderSummaryPage'
          }
        },
        props: (route) => {
          return route.query
        }
      }),
      {
        path: '/blog',
        component: ContentSwitchWrapper,
        meta: {
          layout: 'main',
          pageType: NavigationPage.pageTypes.blog,
          breadcrumb: {
            label: 'section_blog_list',
            name: 'blog'
          }
        },
        children: [
          ...multilanguageRoute({
            paths: {
              cs: '/',
              en: '/'
            },
            name: 'blog',
            component: BlogPage,
            meta: {
              pageType: NavigationPage.pageTypes.blog,
              layout: 'main',
              sidebar: 'dynamic',
              najadaHead: true
            }
          }),
          ...multilanguageRoute({
            name: 'blogDetail',
            paths: {
              cs: ':slug',
              en: ':slug'
            },
            component: BlogDetailPage,
            meta: {
              pageType: NavigationPage.pageTypes.blog,
              layout: 'main',
              sidebar: 'dynamic',
              najadaHead: true,
              breadcrumb: {
                label: '',
                name: 'blogDetail'
              }
            }
          })
        ]
      },
      ...multilanguageRoute({
        paths: {
          cs: '/jak-nakupovat',
          en: '/shopping'
        },
        name: 'shoppingCategory',
        component: RouterWrapView,
        meta: {
          layout: 'main',
          sidebar: 'list',
          breadcrumb: {
            label: 'section_how-to-shop',
            name: 'shoppingCategory'
          }
        },
        redirects: {
          cs: '/jak-nakupovat/obchodni-podminky',
          en: '/shopping/terms'
        },
        props: (route) => {
          return route.params
        },
        children: [
          {
            paths: {
              cs: 'obchodni-podminky',
              en: 'terms'
            },
            name: 'termsPage',
            component: TermsPage,
            meta: {
              layout: 'main',
              articleSlug: 'terms-and-conditions',
              sidebar: 'list',
              breadcrumb: {
                label: 'page_howto-terms',
                name: 'termsPage'
              }
            },
            props: (route) => {
              return route.params
            }
          },
          {
            paths: {
              cs: 'vseobecne-soutezni-podminky',
              en: 'general-contest-terms'
            },
            name: 'contestTermsPage',
            component: RouterWrapView,
            meta: {
              layout: 'main',
              sidebar: 'list',
              breadcrumb: {
                label: 'page_howto-contest-terms',
                name: 'contestTermsPage'
              }
            },
            props: (route) => {
              return route.params
            },
            redirects: {
              cs: '/jak-nakupovat/vseobecne-soutezni-podminky/obecne-soutezni-podminky',
              en: '/shopping/general-contest-terms/general-contest-terms'
            },
            children: [
              {
                paths: {
                  cs: 'obecne-soutezni-podminky',
                  en: 'general-contest-terms'
                },
                name: 'generalContestTermsPage',
                component: GeneralContestTermsPage,
                meta: {
                  layout: 'main',
                  sidebar: 'list',
                  breadcrumb: {
                    label: 'page_howto-general-contest-terms',
                    name: 'contestTermsRateWinPage'
                  }
                },
                props: (route) => {
                  return route.params
                },
              },
              {
                paths: {
                  cs: 'soutez-ohodnot-a-vyhrej',
                  en: 'contest-rate-and-win'
                },
                name: 'contestTermsRateWinPage',
                component: OrderRatingTermsPage,
                meta: {
                  layout: 'main',
                  sidebar: 'list',
                  breadcrumb: {
                    label: 'page_howto-contest-terms-rate-win',
                    name: 'contestTermsRateWinPage'
                  }
                },
                props: (route) => {
                  return route.params
                },
              }
            ],
          },
          {
            paths: {
              cs: 'reklamace',
              en: 'claims'
            },
            name: 'claimsPage',
            component: ClaimsPage,
            meta: {
              layout: 'main',
              articleSlug: 'claims',
              sidebar: 'list',
              breadcrumb: {
                label: 'page_howto-claims',
                name: 'claimsPage'
              }
            },
            props: (route) => {
              return route.params
            }
          },
          {
            paths: {
              cs: 'ochrana-osobnich-udaju',
              en: 'privacy'
            },
            name: 'privacyPage',
            component: PrivacyPage,
            meta: {
              layout: 'main',
              articleSlug: 'privacy-policy',
              sidebar: 'list',
              breadcrumb: {
                label: 'page_howto-privacy',
                name: 'privacyPage'
              }
            },
            props: (route) => {
              return route.params
            }
          },
          {
            paths: {
              cs: 'pruvodce-gradingem',
              en: 'grading-tutorial'
            },
            name: 'gradingTutorialPage',
            component: GradingTutorialPage,
            meta: {
              layout: 'main',
              articleSlug: 'grading-tutorial',
              sidebar: 'list',
              breadcrumb: {
                label: 'page_howto-grading-tutorial',
                name: 'gradingTutorialPage'
              }
            },
            props: (route) => {
              return route.params
            }
          },
          {
            paths: {
              cs: 'platba',
              en: 'payment'
            },
            name: 'shoppingPaymentPage',
            component: ShoppingPaymentPage,
            meta: {
              layout: 'main',
              sidebar: 'list',
              breadcrumb: {
                label: 'page_howto-payment',
                name: 'shoppingPaymentPage'
              }
            },
            props: (route) => {
              return route.params
            }
          },
          {
            paths: {
              cs: 'doprava',
              en: 'shipping'
            },
            name: 'shoppingShippingPage',
            component: ShoppingShippingPage,
            meta: {
              layout: 'main',
              sidebar: 'list',
              breadcrumb: {
                label: 'page_howto-shipping',
                name: 'shoppingShippingPage'
              }
            },
            props: (route) => {
              return route.params
            }
          },
          {
            paths: {
              cs: 'vykup',
              en: 'buylist'
            },
            name: 'shoppingBuylistPage',
            component: ShoppingBuylistPage,
            meta: {
              layout: 'main',
              sidebar: 'list',
              breadcrumb: {
                label: 'page_howto-buylist',
                name: 'shoppingBuylistPage'
              }
            },
            props: (route) => {
              return route.params
            }
          },
          {
            paths: {
              cs: 'registracni-benefity',
              en: 'registration-benefits'
            },
            name: 'registrationBenefitsPage',
            component: RegistrationBenefitsPage,
            meta: {
              layout: 'main',
              sidebar: 'list',
              breadcrumb: {
                label: 'page_howto-registration-benefits',
                name: 'registrationBenefitsPage'
              }
            },
            props: (route) => {
              return route.params
            }
          },
          {
            name: 'shoppingLoyaltyPage',
            paths: {
              cs: 'vernostni-program',
              en: 'loyalty'
            },
            component: ShoppingLoyaltyPage,
            meta: {
              layout: 'main',
              sidebar: 'list',
              breadcrumb: {
                label: 'page_howto-loyalty',
                name: 'shoppingLoyaltyPage'
              }
            },
            props: (route) => {
              return route.params
            }
          },
          {
            paths: {
              cs: 'wantlist',
              en: 'wantlist'
            },
            name: 'shoppingWantlistPage',
            component: ShoppingWantlistPage,
            meta: {
              layout: 'main',
              sidebar: 'list',
              breadcrumb: {
                label: 'page_howto-wantlist',
                name: 'shoppingWantlistPage'
              }
            },
            props: (route) => {
              return route.params
            }
          },
          {
            paths: {
              cs: 'hracuv-lup',
              en: 'reward'
            },
            name: 'shoppingRewardPage',
            component: ShoppingRewardPage,
            meta: {
              layout: 'main',
              sidebar: 'list',
              breadcrumb: {
                label: 'page_howto-reward',
                name: 'shoppingRewardPage'
              }
            },
            props: (route) => {
              return route.params
            }
          }
        ]
      }),
      ...multilanguageRoute({
        paths: {
          cs: '/kusove-karty',
          en: '/single-cards'
        },
        name: 'singles',
        redirects: {
          cs: '/kusove-karty/magic-the-gathering/prodej-kusovek',
          en: '/single-cards/magic-the-gathering/sell',
        },
        component: RouterWrapView,
        meta: {
          sidebar: 'dynamic',
          layout: 'main',
          pageType: NavigationPage.pageTypes.mtgSingles
        },
        children: [
          {
            paths: {
              cs: 'magic-the-gathering',
              en: 'magic-the-gathering',
            },
            name: 'singles_guide_mtg',
            redirects: {
              cs: '/kusove-karty/magic-the-gathering/prodej-kusovek',
              en: '/single-cards/magic-the-gathering/sell',
            },
            component: RouterWrapView,
            children: [
              {
                paths: {
                  en: 'sell',
                  cs: 'prodej-kusovek'
                },
                name: 'singles_guide_mtg_sell_view',
                component: RouterWrapView,
                children: [
                  {
                    paths: {
                      en: '',
                      cs: ''
                    },
                    name: 'singles_guide_mtg_sell',
                    component: MTGSinglesPage,
                    meta: {
                      mtgSinglesSwitch: true,
                      mtgSinglesSwitchSection: 'singles_guide_mtg_sell',
                    },
                  },
                  {
                    paths: {
                      cs: 'hromadny-nakup',
                      en: 'bulk-purchase'
                    },
                    name: 'singles_guide_mtg_bulk_purchase',
                    component: MTGPurchaseBulkPage,
                    meta: {
                      sidebar: 'mtgSinglesBulk',
                      layout: 'main',
                      mtgSinglesSwitch: true,
                      mtgSinglesSwitchSection: 'singles_guide_mtg_bulk_purchase',
                      breadcrumb: {
                        name: 'mtg_purchase_bulk',
                        label: 'mtg_purchase_bulk'
                      }
                    }
                  },
                ],
              },
              {
                paths: {
                  en: 'buylist',
                  cs: 'vykup-kusovek'
                },
                component: RouterWrapView,
                name: 'singles_guide_mtg_buylist_view',
                children: [
                  {
                    paths: {
                      cs: '',
                      en: ''
                    },
                    name: 'singles_guide_mtg_buylist',
                    component: MTGBuylistSinglesPage,
                    meta: {
                      sidebar: 'dynamic',
                      layout: 'main',
                      mtgBuylistSwitch: true,
                      mtgBuylistSwitchSection: 'singles_guide_mtg_buylist',
                    },
                  },
                  {
                    paths: {
                      cs: 'balene-produkty',
                      en: 'sealed-products'
                    },
                    name: 'singles_guide_mtg_buylist_products',
                    component: MTGBuylistProductsPage,
                    meta: {
                      sidebar: null,
                      layout: 'main',
                      mtgBuylistSwitch: true,
                      mtgBuylistSwitchSection: 'singles_guide_mtg_buylist_products',
                      breadcrumb: {
                        name: 'mtg_products',
                        label: 'mtg_products'
                      }
                    }
                  },
                  {
                    paths: {
                      cs: 'hromadny-vykup',
                      en: 'bulk-buylist'
                    },
                    name: 'singles_guide_mtg_bulk_buylist',
                    component: MTGBuylistBulkPage,
                    meta: {
                      sidebar: 'mtgSinglesBulk',
                      layout: 'main',
                      mtgBuylistSwitch: true,
                      mtgBuylistSwitchSection: 'singles_guide_mtg_bulk_buylis',
                      breadcrumb: {
                        name: 'mtg_buylist_bulk',
                        label: 'mtg_buylist_bulk'
                      }
                    }
                  }
                ],
              },
            ],
          },
          {
            paths: {
              cs: ':game',
              en: ':game',
            },
            name: 'singles_guide',
            redirects: {
              cs: '/kusove-karty/:game/prodej-kusovek',
              en: '/prodej-kusovek/:game/sell'
            },
            component: RouterWrapView,
            children: [
              {
                paths: {
                  en: 'sell',
                  cs: 'prodej-kusovek'
                },
                name: 'singles_guide_sell',
                component: SinglesSellPage,
              }
            ],
          }
        ],
      }),
      ...multilanguageRoute({
        paths: {
          cs: '/vyhledavani',
          en: '/search'
        },
        name: 'search',
        component: SearchResultPage,
        meta: {
          sidebar: 'search',
          layout: 'main',
          searchSwitch: true,
          breadcrumb: {
            name: 'search',
            label: 'page_search'
          }
        },
        props: (route) => {
          return route.params
        }
      }),
      ...multilanguageRoute({
        name: 'checkout',
        paths: {
          cs: '/checkout',
          en: '/checkout'
        },
        component: CheckoutWrapper,
        meta: {
          layout: 'main',
          breadcrumb: {
            name: 'checkout',
            label: 'page_checkout'
          }
        },
        redirects: {
          cs: '/checkout/obsah-kosiku',
          en: '/checkout/cart-contents'
        },
        props: (route) => {
          return route.params
        },
        children: [
          {
            name: 'checkoutStep1',
            paths:{
              cs: 'obsah-kosiku',
              en: 'cart-contents'
            },
            component: CheckoutStep1,
            meta: {
              breadcrumb: {
                name: 'checkoutStep1',
                label: 'checkout_step_1'
              }
            }
          },
          {
            name: 'checkoutStep2',
            paths: {
              cs: 'doprava-a-platba',
              en: 'shipping-and-payment'
            },
            component: CheckoutStep2,
            meta: {
              breadcrumb: {
                name: 'checkoutStep2',
                label: 'checkout_step_2'
              }
            }
          },
          {
            name: 'checkoutStep3',
            paths: {
              cs: 'shrnuti-objednavky',
              en: 'overview'
            },
            component: CheckoutStep3,
            meta: {
              breadcrumb: {
                name: 'checkoutStep3',
                label: 'checkout_step_3'
              }
            }
          },
          {
            name: 'paymentCompleted',
            paths: {
              cs: 'platba/dokonceno',
              en: 'payment/completed'
            },
            component: CheckoutCompleted,
            props: (route) => {
              return route.params
            },
            meta: {
              breadcrumb: {
                name: 'payment',
                label: 'page_checkout_completed'
              }
            }
          }
        ]
      }),

      ...multilanguageRoute({
        name: 'checkoutBuyup',
        paths: {
          cs: '/checkout-vykup',
          en: '/checkout-buyup'
        },
        component: CheckoutBuyupWrapper,
        meta: {
          layout: 'main',
          breadcrumb: {
            name: 'checkoutBuyup',
            label: 'page_checkout_buyup'
          }
        },
        redirects: {
          cs: '/checkout-vykup/obsah-kosiku',
          en: '/checkout-buyup/cart-contents'
        },
        props: (route) => {
          return route.params
        },
        children: [
          {
            name: 'checkoutBuyupStep1',
            paths: {
              cs: 'obsah-kosiku',
              en: 'cart-contents'
            },
            meta: {
              breadcrumb: {
                name: 'checkoutBuyupStep1',
                label: 'checkout_buy_step_1'
              }
            },
            component: CheckoutBuyupStep1
          },
          {
            name: 'checkoutBuyupStep2',
            paths: {
              cs: 'doprava-a-platba',
              en: 'shipping-and-payment'
            },
            meta: {
              breadcrumb: {
                name: 'checkoutBuyupStep2',
                label: 'checkout_buy_step_2'
              }
            },
            component: CheckoutBuyupStep2
          },
          {
            name: 'checkoutBuyupStep3',
            paths: {
              cs: 'shrnuti-objednavky',
              en: 'overview'
            },
            meta: {
              breadcrumb: {
                name: 'checkoutBuyupStep3',
                label: 'checkout_buy_step_3'
              }
            },
            component: CheckoutBuyupStep3
          },
          {
            name: 'checkoutBuyupDone',
            paths: {
              cs: 'dokonceno',
              en: 'completed'
            },
            meta: {
              breadcrumb: {
                name: 'checkoutBuyupDone',
                label: 'page_checkout_buyup_completed'
              }
            },
            component: CheckoutBuyupCompleted
          }
        ]
      }),

      ...multilanguageRoute({
        name: 'gamingClub',
        paths: {
          cs: '/herna',
          en: '/game-club'
        },
        component: ContentSwitchWrapper,
        redirects: {
          cs: {name: 'gamingClubTournaments__cs'},
          en: {name: 'gamingClubTournaments__en'}
        },
        meta: {
          sidebar: 'gamingClub',
          layout: 'main',
          breadcrumb: {
            name: 'gamingClub',
            label: 'page_games-club'
          }
        },
        children: [
          {
            name: 'gamingClubLeagueParent',
            paths: {
              cs: 'liga/:leagueId',
              en: 'league/:leagueId'
            },
            component: ContentSwitchWrapper,
            props: (route) => {
              return route.params
            },
            children: [
              {
                name: 'gamingClubLeague',
                paths: {
                  cs: '',
                  en: ''
                },
                component: GCLeaguePage,
                props: (route) => {
                  return route.params
                },
                meta: {
                  breadcrumb: {
                    name: 'gamingClubLeague',
                    label: 'page_gaming_club_league_title'
                  }
                }
              },
              {
                name: 'gamingClubLeagueHistory',
                paths: {
                  cs: 'historie-ligy',
                  en: 'league-history'
                },
                component: GCLeagueHistoryPage,
                props: (route) => {
                  return route.params
                },
                meta: {
                  breadcrumb: {
                    name: 'gamingClubLeagueHistory',
                    label: 'page_gaming_club_league_history_title'
                  }
                }
              },
              {
                name: 'gamingClubLeagueStatistics',
                paths: {
                  cs: 'statistika-ligy',
                  en: 'league-statistics'
                },
                component: GCLeagueStatisticsPage,
                props: (route) => {
                  return route.params
                },
                meta: {
                  breadcrumb: {
                    name: 'gamingClubLeagueStatistics',
                    label: 'page_gaming_club_league_statistics_title'
                  }
                }
              },
              {
                name: 'gamingClubLeagueRules',
                paths: {
                  cs: 'pravidla-ligy',
                  en: 'league-rules'
                },
                component: GCLeagueRulesPage,
                props: (route) => {
                  return route.params
                },
                meta: {
                  breadcrumb: {
                    name: 'gamingClubLeagueRules',
                    label: 'page_gaming_club_league_rules_title'
                  }
                }
              },
            ],
          },

          {
            name: 'gamingClubTournaments',
            paths: {
              cs: 'turnaje',
              en: 'tournaments'
            },
            component: GCTournamentsPage,
            meta: {
              breadcrumb: {
                name: 'gamingClubTournaments',
                label: 'page_gaming_club_tournaments_allocation_title'
              }
            }
          },
          {
            name: 'gamingClubTournamentsSchedule',
            paths: {
              cs: 'kalendar-turnaju',
              en: 'tournaments-schedule'
            },
            component: GCTournamentsSchedulePage,
            meta: {
              breadcrumb: {
                name: 'gamingClubTournamentsSchedule',
                label: 'page_gaming_club_tournaments_schedule_title'
              }
            }
          },
          {
            name: 'gamingClubUserTournaments',
            paths: {
              cs: 'moje-turnaje',
              en: 'my-tournaments'
            },
            component: GCUserTournamentsPage,
            meta: {
              breadcrumb: {
                name: 'gamingClubUserTournaments',
                label: 'page_gaming_club_user_tournaments_title'
              },
              requiresAuth: true,
            }
          },
          {
            name: 'gamingClubPlayerSettings',
            paths: {
              cs: 'moje-nastaveni',
              en: 'my-settings'
            },
            component: GCPlayerSettingsPage,
            meta: {
              breadcrumb: {
                name: 'gamingClubPlayerSettings',
                label: 'page_gaming_club_player_settings_title'
              },
              requiresAuth: true,
            }
          },
          {
            name: 'gamingClubPlayerStatsOverview',
            paths: {
              cs: 'moje-prehledy',
              en: 'my-overviews'
            },
            component: GCPlayerStatsOverviewPage,
            meta: {
              breadcrumb: {
                name: 'gamingClubPlayerStatsOverview',
                label: 'page_gaming_club_player_stats_overview_title'
              },
              requiresAuth: true,
            }
          },
          {
            paths: {
              cs: 'jak-to-u-nas-funguje',
              en: 'how-it-works'
            },
            name: 'gamingClubHowToPage',
            component: GamingClubAboutPage,
            meta: {
              layout: 'main',
              articleSlug: 'gaming-club-how-it-works',
              breadcrumb: {
                label: 'page_howto-gaming-club',
                name: 'gamingClubPage'
              }
            },
            props: (route) => {
              return route.params
            }
          },
        ]
      }),

      ...multilanguageRoute({
        name: 'user',
        paths: {
          cs: '/muj-ucet',
          en: '/my-account'
        },
        component: UserWrapper,
        redirects: {
          cs: '/muj-ucet/objednavky',
          en: '/my-account/orders'
        },
        meta: {
          sidebar: 'user',
          layout: 'main',
          requiresAuth: true,
          breadcrumb: {
            name: 'user',
            label: 'page_user'
          }
        },
        children: [
          {
            name: 'userOrders',
            paths: {
              cs: 'objednavky',
              en: 'orders'
            },
            component: UserOrdersPage,
            meta: {
              breadcrumb: {
                name: 'userOrders',
                label: 'page_user_orders'
              }
            }
          },
          {
            name: 'userBuyup',
            paths: {
              cs: 'vykupy',
              en: 'sell-orders'
            },
            component: UserBuyupsPage,
            meta: {
              breadcrumb: {
                name: 'userBuyup',
                label: 'page_user_buyups'
              }
            }
          },
          {
            name: 'userShoppingLists',
            paths: {
              cs: 'nakupni-seznamy',
              en: 'shopping-lists'
            },
            component: UserShoppingListsPage,
            meta: {
              breadcrumb: {
                name: 'userShoppingLists',
                label: 'page_user_shoppingLists'
              }
            }
          },
          {
            name: 'userCredit',
            paths: {
              cs: 'pohyby-kreditu',
              en: 'credit'
            },
            component: UserCreditPage,
            meta: {
              breadcrumb: {
                name: 'userCredit',
                label: 'page_user_credits'
              }
            }
          },
          {
            name: 'userTournaments',
            paths: {
              cs: 'turnaje',
              en: 'tournaments'
            },
            component: UserTournamentsPage,
            meta: {
              breadcrumb: {
                name: 'userTournaments',
                label: 'page_user_tournaments'
              }
            }
          },
          {
            name: 'userAddresses',
            paths: {
              cs: 'adresy',
              en: 'addresses'
            },
            component: UserAddressesPage,
            meta: {
              breadcrumb: {
                name: 'userAddresses',
                label: 'page_user_addresses'
              }
            }
          },
          {
            name: 'userSettings',
            paths: {
              cs: 'nastaveni',
              en: 'settings'
            },
            component: UserSettingsPage,
            meta: {
              breadcrumb: {
                name: 'userSettings',
                label: 'page_user_settings'
              }
            }
          },
          {
            name: 'userVoucher',
            paths: {
              cs: 'vouchery',
              en: 'voucher'
            },
            component: UserVoucherPage,
            meta: {
              breadcrumb: {
                name: 'userVoucher',
                label: 'page_user_voucher'
              }
            }
          },
          {
            name: 'userWantlist',
            paths: {
              cs: 'wantlist',
              en: 'wantlist'
            },
            component: UserWantlistPage,
            meta: {
              breadcrumb: {
                name: 'userWantlist',
                label: 'page_user_wantlist'
              }
            }
          }
        ],
        props: (route) => {
          return route.params
        }
      }),
      ...multilanguageRoute({
        name: 'careerPage',
        paths: {
          cs: '/kariera',
          en: '/career'
        },
        meta: {
          layout: 'main',
          requiresAuth: false,
          breadcrumb: {
            name: 'careerPage',
            label: 'page_career'
          }
        },
        component: CareerPage
      }),
      {
        name: 'dynamicPage',
        path: '*',
        meta: {
          sidebar: 'dynamic',
          layout: 'main',
          requiresAuth: false
        },
        component: DynamicPage
      },
    ]
  })
}
