import { getLoginStatus, init } from '~/services/FacebookService'

export default function ({ app: { $log }, $config, store }) {
  // wait for facebook sdk to initialize before starting the vue app
  window.fbAsyncInit = async function () {
   init({
      appId: $config.facebook.appId,
      cookie: true,
      xfbml: true,
      version: 'v19.0'
    });

    // auto authenticate with the api if already logged in with facebook
    const { authResponse } = await getLoginStatus()
    if (authResponse) {
      const { accessToken, graphDomain } = authResponse
      $log.log('authenticated', 'FB')
      store.dispatch('authentication/loginOAuth', {
        token: accessToken,
        backend: graphDomain,
        appClientId: $config.facebook.appClientId
      })
    } else {
      $log.log('not authenticated', 'FB')
    }
  };

  // load facebook sdk script
  (function (d, s, id) {
    // eslint-disable-next-line prefer-const
    let js; let fjs = d.getElementsByTagName(s)[0]
    if (d.getElementById(id)) { return; }
    // eslint-disable-next-line prefer-const
    js = d.createElement(s)
    js.id = id
    js.defer = true
    js.src = "https://connect.facebook.net/en_US/sdk.js"
    fjs.parentNode.insertBefore(js, fjs)
  }(document, 'script', 'facebook-jssdk'));
}
