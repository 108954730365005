import createPersistedState from 'vuex-persistedstate'

export default ({store}) => {
  createPersistedState({
    key: 'najada',
    paths: [
      'authentication',
      'cart',
      'cartBuyup',
      'catalog.featured',
      'checkout',
      'checkoutBuyup',
      'general.cookies_consent',
      'general.settings',
      'general.bulk',
      'recentProducts'
    ]
  })(store)
}
