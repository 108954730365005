import Vue from 'vue'
import isEmpty from 'lodash/isEmpty'
import isObject from 'lodash/isObject'
import LogService from '~/services/LogService'
import { setUnauthorizedAction, setTooManyRequestsAction } from '~/services/network/utils/axios'
import { setBaseUrl, setToken, setCancellationSupport } from '~/workers/network.worker'

const fetchUserData = async function (method) {
  try {
    await method
  } catch (e) {
    const isUnauthorized = isObject(e) && ('code' in e) && e.code === 401
    if(!isUnauthorized) {
      LogService.error(e)
    }
  }
}

export default async function ({ app, $config, store }) {
  // check if spa fallback, by checking if a value in store is empty for example
  setBaseUrl($config.apiEndpoint)
  setCancellationSupport(window && 'AbortController' in window)

  setUnauthorizedAction(() => {
    store.dispatch('authentication/logoutUnauthorized')
    app.router.replace({ name: 'home' })
  })
  setTooManyRequestsAction((payload) => {
    if(payload && payload.message) {
      Vue.$toast.error(payload.message)
    }
  })

  if (isEmpty(store.state.menu.menu)) {
    await store.dispatch('menu/getMenu')
  }

  store.dispatch('authentication/mountToken')

  if(store.getters['authentication/isAuthenticated']) {
    setToken(store.getters['authentication/authToken'])
    fetchUserData(store.dispatch('authentication/loadProfileDetail', {
      updateDependencies: true
    }))
  }

  store.dispatch('general/hello')
  store.dispatch('cart/init')
  store.dispatch('cartBuyup/init')
  store.dispatch('general/fetchStatusMessage')

  setTimeout(function () {
    store.dispatch('shoppingLists/getLastShoppingList')
    store.dispatch('gamingClub/fetchDependencies')
  }, 0)
}
