import Vue from 'vue'

const Tooltip = {

  eventBus: new Vue(),

  /**
   *
   * @param {{}} params
   * @param {number} params.x
   * @param {number} params.y
   * @param {string} params.title
   * @param {string} params.content
   */
  show (params) {
    Tooltip.eventBus.$emit('show', params)
  },

  hide () {
    Tooltip.eventBus.$emit('hide')
  }
}

export default ({ app }, inject) => {
  inject('tooltip', Tooltip)
}
